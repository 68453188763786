<template>
  <div>
    <c-tab
      type="tabcard"
      align="left"
      :inlineLabel="true"
      :tabItems="tabItems"
      height="500px"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :selectProcessCd.sync="popupParam.selectProcessCd"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
export default {
  name: 'process-non-improve',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        selectProcessCd: '',
      }),
    },
  },
  data() {
    return {
      tab: 'hazop',
      tabItems: [
        { name: 'hazop', icon: 'edit', label: 'HAZOP', component: () => import(`${'./processHazopNonImprove.vue'}`) },
        { name: 'kras', icon: 'edit', label: 'KRAS', component: () => import(`${'./processKrasNonImprove.vue'}`) },
        { name: 'kpsr', icon: 'edit', label: 'K-PSR', component: () => import(`${'./processKpsrNonImprove.vue'}`) },
        { name: 'ckechlist', icon: 'edit', label: 'Check-list', component: () => import(`${'./processChecklistNonImprove.vue'}`) },
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
    },
  }
};
</script>
